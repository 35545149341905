import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import styles from "./index.module.scss";
import axios from "axios/axios";
import "./games.scss";
import { Toast } from "primereact/toast";
export default function Games() {
	const toast = useRef(null);
	const [showMobileModal, setShowMobileModal] = useState(false);
	const gamesDetails = [
		{
			name: "Bull Run",
			description:
				"Bull Run is an exhilarating mobile endless runner game set in the vibrant Bullieverse. Players dash through dynamic landscapes, dodging obstacles and collecting rewards, all while competing in thrilling tournaments. Immerse yourself in a world where agility and strategy blend seamlessly with captivating graphics and engaging gameplay",
			gameposter: "bullrun.png",
			onlyMobile: true,
		},
		{
			name: "Necrodemic",
			description:
				"Necrodemic is a Free to Play survival based thriller. Defeat zombie bears to earn essence. Essence can be used to purchase more powerful weapons, ammo and perks that increase players chances of survival. This will also include lootbox prizes and a daily leaderboard that rewards players.",
			gameposter: "Necrodemic.png",
			onlyMobile: false,
		},
	];

	const downloadGame = () => {
		// if (navigator?.userAgentData && navigator?.userAgentData?.platform) {
		// 	return navigator.userAgentData.platform;
		// }
		const userAgent = navigator.userAgent.toLowerCase();
		if (userAgent.includes("windows")) {
			window.open("https://store.epicgames.com/en-US/p/necrodemic-d50b38");
		} else if (userAgent.includes("mac")) {
			window.open("https://cobi-service-files.s3.us-east-1.amazonaws.com/Necrodemic_4.1.2_mac.zip");
		}
	};

	return (
		<div className={styles.rightPanel}>
			<Dialog
				focusOnShow={false}
				header="Choose your platform"
				className="mobileGameDownloadDialog"
				headerClassName="headerClassName"
				visible={showMobileModal}
				style={{ width: "30vw" }}
				breakpoints={{ "960px": "75vw", "641px": "95vw" }}
				onHide={() => {
					if (!showMobileModal) return;
					setShowMobileModal(false);
				}}
			>
				<div className="lists">
					<div className="list">
						<div className="left">
							<img src="/assets/games/apple-home.png" alt="icon" className="icon" />
							<div className="brand">
								App Store
								<div className="symbol">
									<img src="/assets/games/symbols_ios.png" alt="icon" className="icon" />
									ios
								</div>
							</div>
						</div>
						<a href="https://apps.apple.com/gb/app/bull-run-bullieverse/id6473615643" target="_blank" rel="noreferrer" className="right">
							Download
						</a>
					</div>
					<div className="list">
						<div className="left">
							<img src="/assets/games/android-home.png" alt="icon" className="icon" />
							<div className="brand">
								Play Store
								<div className="symbol">
									<img src="/assets/games/symbols_android.png" alt="icon" className="icon" />
									Android
								</div>
							</div>
						</div>
						<a href="https://play.google.com/store/apps/details?id=com.bullieverse.BullRun&pcampaignid=web_share" target="_blank" rel="noreferrer" className="right">
							Download
						</a>
					</div>
				</div>
			</Dialog>
			<Toast ref={toast} />
			<h1>Discover All the Games Available on Passport!</h1>
			<h2>Browse, download, and play seamlessly—all in one place.</h2>

			<div className={styles.cards}>
				{gamesDetails.map((game, index) => (
					<div className={styles.card} key={index}>
						<img src={`/assets/games/${game.gameposter}`} alt="banner" className={styles.banner} />
						<div>
							<div className={styles.developer}>Bullieverse</div>
							<div className={styles.name}>
								{game.name}
								<span className={styles.beta}>Beta</span>
							</div>
							{game.onlyMobile ? (
								<button className={styles.downloadButton} onClick={() => setShowMobileModal(true)}>
									Download
								</button>
							) : (
								<button className={styles.downloadButton} onClick={() => downloadGame()}>
									Download
								</button>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
