import axios from "axios/axios";
import { getWalletBalance } from "thirdweb/wallets";
import { getAllActiveSigners, getAllSigners, addSessionKey } from "thirdweb/extensions/erc4337";
import { getContract } from "thirdweb";
import moment from "moment";
import { sendTransaction } from "thirdweb";
import Config from "Config";
import Web3 from "web3";
import { ethers } from "ethers";

const getUserDetails = () => async (dispatch, getState) => {
	try {
		let {
			response: { data },
		} = await axios.get("/auth/user/details");
		dispatch({ type: "LOAD_USER_DETAILS", payload: data });
	} catch (error) {
		console.error(error);
	}
};

const getBullTokenPrice = () => async (dispatch, getState) => {
	try {
		let {
			response: { data },
		} = await axios.get("https://api.bullieverse.com/utils/getTokenPrice?coinName=bull");
		dispatch({ type: "SET_BULL_TOKEN_PRICE", payload: data[0].price });
	} catch (error) {
		console.error(error);
	}
};

const getEthTokenPrice = () => async (dispatch, getState) => {
	try {
		// let { response: { data } } = await axios.get("https://api.bullieverse.com/utils/getTokenPrice?coinName=eth")
		dispatch({ type: "SET_ETH_TOKEN_PRICE", payload: 1 });
	} catch (error) {
		console.error(error);
	}
};

const getUserWalletBalance = () => async (dispatch, getState) => {
	const { client, defaultChain, userAccountDetails, ERC20ContractAddress, USDCTokenAddress } = getState().auth;
	dispatch({ type: "LOAD_USER_WALLET_BALANCE", payload: {} });
	try {
		const SBullBalance = await getWalletBalance({
			address: userAccountDetails.smartAccount.address,
			client,
			chain: defaultChain,
			tokenAddress: ERC20ContractAddress,
		});
		const SEthBalance = await getWalletBalance({
			address: userAccountDetails.smartAccount.address,
			client,
			chain: defaultChain,
			tokenAddress: USDCTokenAddress,
		});
		const PBullBalance = await getWalletBalance({
			address: userAccountDetails.personalAccount.address,
			client,
			chain: defaultChain,
			tokenAddress: ERC20ContractAddress,
		});
		const PEthBalance = await getWalletBalance({
			address: userAccountDetails.personalAccount.address,
			client,
			chain: defaultChain,
			tokenAddress: USDCTokenAddress,
		});
		dispatch({
			type: "LOAD_USER_WALLET_BALANCE",
			payload: {
				SmartWallet: {
					bullBalance: SBullBalance,
					ethBalance: SEthBalance,
				},
				PersonalWallet: {
					bullBalance: PBullBalance,
					ethBalance: PEthBalance,
				},
			},
		});
	} catch (error) {
		dispatch({
			type: "LOAD_USER_WALLET_BALANCE",
			payload: {
				SmartWallet: {
					bullBalance: {
						displayValue: "0",
						symbol: "BULL",
					},
					ethBalance: {
						displayValue: "0",
						symbol: "ETH",
					},
				},
				PersonalWallet: {
					bullBalance: {
						displayValue: "0",
						symbol: "BULL",
					},
					ethBalance: {
						displayValue: "0",
						symbol: "ETH",
					},
				},
			},
		});
	}
};

const getAllSessionKeys = () => async (dispatch, getState) => {
	const { userAccountDetails, defaultChain, client } = getState().auth;
	let smartAccount = userAccountDetails.smartAccount;
	try {
		const result = await getAllActiveSigners({
			contract: getContract({
				address: smartAccount?.address,
				chain: defaultChain,
				client: client,
			}),
		});
		dispatch({ type: "LOAD_USER_SESION_KEYS", payload: result });
		return result.length;
	} catch (error) {
		dispatch({ type: "LOAD_USER_SESION_KEYS", payload: [] });
		return 0;
	}
};

const getAllSigner = () => async (dispatch, getState) => {
	const { userAccountDetails, defaultChain, client } = getState().auth;
	let smartAccount = userAccountDetails.smartAccount;
	try {
		const result = await getAllSigners({
			contract: getContract({
				address: smartAccount?.address,
				chain: defaultChain,
				client: client,
			}),
		});
		dispatch({ type: "LOAD_USER_ALL_SIGNER", payload: result });
		return result.length;
	} catch (error) {
		dispatch({ type: "LOAD_USER_ALL_SIGNER", payload: [] });
		return 0;
	}
};

const splitWalletAddress = (walletAddress) => (dispatch, getState) => {
	if (walletAddress) {
		return walletAddress.substring(0, 6) + "..." + walletAddress.substring(walletAddress.length - 4, walletAddress.length);
	}
};

const parseBalance = (balance, limit) => (dispatch, getState) => {
	balance = balance !== "" ? Number(balance) : "";
	const length = balance.toString().length;

	if (limit) {
		return length > 6 ? balance.toFixed(limit) : balance;
	} else {
		return length > 5 ? balance.toFixed(6) : balance;
	}
};

const addUserSessionKey = (duration) => async (dispatch, getState) => {
	const { userAccountDetails, defaultChain, client } = getState().auth;
	dispatch({ type: "ACTIVATING_SESSION_KEY", payload: true });
	try {
		let smartAccount = userAccountDetails.smartAccount;
		let endDuration;
		if (duration === 0) {
			endDuration = moment().add(2, "years").valueOf();
		} else {
			endDuration = moment().add(duration, "months").valueOf();
			endDuration = moment(endDuration).add("23", "hours").valueOf();
		}
		const startTime = new Date();
		const endTime = new Date(endDuration);
		const transaction = addSessionKey({
			contract: getContract({
				address: smartAccount?.address,
				chain: defaultChain,
				client: client,
			}),
			account: smartAccount,
			sessionKeyAddress: Config.SESSION_KEY_ADDRESS,
			permissions: {
				approvedTargets: "*",
				nativeTokenLimitPerTransaction: 1,
				permissionStartTimestamp: startTime,
				permissionEndTimestamp: endTime,
			},
		});
		let { transactionHash } = await sendTransaction({ transaction, account: smartAccount });
		console.log(transactionHash);
	} catch (error) {
		console.error(error);
		throw error;
	} finally {
		dispatch({ type: "ACTIVATING_SESSION_KEY", payload: false });
		return true;
	}
};

const getCredits = () => async (dispatch, getState) => {
	try {
		const { response } = await axios.get("/gaming/bullrun/v1/point");
		dispatch({ type: "LOAD_USER_POINTS", payload: response });
	} catch (error) {
		dispatch({ type: "LOAD_USER_POINTS", payload: {} });
	}
};

const bullBalanceOf = () => async (dispatch, getState) => {
	let { userDetails, sepolia_INFURA_URL } = getState().auth;
	let currentProvider = new Web3(sepolia_INFURA_URL);
	let contract = new currentProvider.eth.Contract(Config.BULL_TOKEN_ABI, Config.ERC20ContractAddress).methods;
	return await contract.balanceOf(userDetails?.ethAddress).call();
};

const allowance = () => async (dispatch, getState) => {
	let { userDetails, sepolia_INFURA_URL } = getState().auth;
	let currentProvider = new Web3(sepolia_INFURA_URL);
	let contract = new currentProvider.eth.Contract(Config.BULL_TOKEN_ABI, Config.ERC20ContractAddress).methods;
	return await contract.allowance(userDetails?.ethAddress, Config.STAKING_CONTRACT_ADDRESS).call();
};

const approve = (amount) => async (dispatch, getState) => {
	// amount += 1;
	let { web3Provider } = getState().auth;
	const signer = web3Provider.getSigner();
	let contract = new ethers.Contract(Config.ERC20ContractAddress, Config.BULL_TOKEN_ABI, signer);
	// amount = ((amount) * Math.pow(10, 18));
	let { hash } = await contract.approve(Config.STAKING_CONTRACT_ADDRESS, amount);
	return await web3Provider.waitForTransaction(hash);
};

const stake = (amount) => async (dispatch, getState) => {
	let { web3Provider } = getState().auth;
	const signer = web3Provider.getSigner();
	let contract = new ethers.Contract(Config.STAKING_CONTRACT_ADDRESS, Config.STAKING_ABI, signer);
	// amount = ((amount) * Math.pow(10, 18));
	let { hash } = await contract.stake(amount);
	return await web3Provider.waitForTransaction(hash);
};

const currentStaked = () => async (dispatch, getState) => {
	let { userDetails, sepolia_INFURA_URL } = getState().auth;
	let currentProvider = new Web3(sepolia_INFURA_URL);
	let contract = new currentProvider.eth.Contract(Config.STAKING_ABI, Config.STAKING_CONTRACT_ADDRESS).methods;
	return await contract.currentStaked(userDetails?.ethAddress).call();
};

const unLockToken = (amount) => async (dispatch, getState) => {
	let { web3Provider } = getState().auth;
	const signer = web3Provider.getSigner();
	let contract = new ethers.Contract(Config.STAKING_CONTRACT_ADDRESS, Config.STAKING_ABI, signer);
	let { hash } = await contract.unLockToken(amount);
	return await web3Provider.waitForTransaction(hash);
};

const unlockCount = () => async (dispatch, getState) => {
	let { userDetails, sepolia_INFURA_URL } = getState().auth;
	let currentProvider = new Web3(sepolia_INFURA_URL);
	let contract = new currentProvider.eth.Contract(Config.STAKING_ABI, Config.STAKING_CONTRACT_ADDRESS).methods;
	return await contract.unlockCount(userDetails?.ethAddress).call();
};

const getUnlocks = (start, end) => async (dispatch, getState) => {
	let { userDetails, sepolia_INFURA_URL } = getState().auth;
	let currentProvider = new Web3(sepolia_INFURA_URL);
	let contract = new currentProvider.eth.Contract(Config.STAKING_ABI, Config.STAKING_CONTRACT_ADDRESS).methods;
	return await contract.getUnlocks(userDetails.ethAddress, start, end).call();
};

const withdrawAmount = (indexes) => async (dispatch, getState) => {
	let { web3Provider } = getState().auth;
	const signer = web3Provider.getSigner();
	let contract = new ethers.Contract(Config.STAKING_CONTRACT_ADDRESS, Config.STAKING_ABI, signer);
	let { hash } = await contract.withdraw(indexes);
	return await web3Provider.waitForTransaction(hash);
};

const authActions = {
	getUserDetails,
	getBullTokenPrice,
	getEthTokenPrice,
	splitWalletAddress,
	getUserWalletBalance,
	getAllSessionKeys,
	parseBalance,
	getAllSigner,
	addUserSessionKey,
	getCredits,
	bullBalanceOf,
	allowance,
	approve,
	stake,
	currentStaked,
	unLockToken,
	unlockCount,
	getUnlocks,
	withdrawAmount,
};

export default authActions;
